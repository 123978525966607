var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTableAdvance',{staticClass:"mb-4",attrs:{"items":_vm.roles,"fields":_vm.fields,"store":"auth.role_list","resource":"/admin/roles","enterable":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":""}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"permissions_count",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('TMessage',{attrs:{"content":item.permissions_count,"noTranslate":"","alignment":"center"}})],1)]}},{key:"users_count",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.users_count,"noTranslate":"","alignment":"center"}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }